<template>
    <div class="row result">

        <div class="col-12 mt-5">
            <h2>Vyhodnocení</h2>

            <p>Ve screeningovém dotazníku jste získal {{ $route.params.score }} bodů, což odpovídá
                percentilu {{ $route.params.percentile }}. Jinými slovy, {{ $route.params.percentile }}%
                náhodně vybraných studentů VŠE vykazuje stejné nebo menší studijní obtíže, než jaké jste reportoval/a vy.
                Čím vyšší číslo, tím jsou vámi reportované obtíže závažnější. Studenti s poruchami učení,
                pozornosti a podobnými dosahují zpravidla alespoň 80. percentilu, v průměru pak dokonce 96. percentilu.</p>

            <template v-if="diagnosed">
                <p>V testu jste uvedl/a, že vám v minulosti byla diagnostikována některá z poruch učení nebo pozornosti.
                    S vysokou pravděpodobností u vás taková porucha přetrvává. Může vám znesnadňovat studium a
                    také zhoršovat vaše studijní výsledky.</p>

                <ResultMoreInfo />
            </template>

            <template v-else-if="$route.params.score>=44">
                <p>Z výsledku screeningového testu vyplývá, že s velkou pravděpodobností máte určitou formu poruchy učení nebo pozornosti.
                    Tyto obtíže vám mohou znesnadňovat studium a také zhoršovat vaše studijní výsledky.</p>

                <ResultMoreInfo />
            </template>

            <template v-else-if="$route.params.score>=32">
                <p>Z výsledku screeningového testu vyplývá, že je pravděpodobné, že máte určitou formu poruchy učení
                    nebo pozornosti. Tyto obtíže vám mohou znesnadňovat studium a také zhoršovat vaše studijní výsledky. </p>

                <ResultMoreInfo />
            </template>

            <template v-else>
                <p>Dle dosažených bodů s vysokou pravděpodobností specifickou poruchou učení anebo pozornosti netrpíte.
                    Pokud jste však na některou z otázek odpověděl/a zaškrtnutím „často“ anebo „téměř vždy“ (2 - 3 body)
                    a jste si vědom/a toho, že daná oblast/téma Vám přináší trvalé obtíže při studiu, doporučujeme Vám
                    kontaktovat Středisko handicapovaných studentů VŠE, které Vám nabídne podporu.</p>
                <p>Můžete se dozvědět více o:</p>
                <ul>
                    <li>strategiích efektivního učení</li>
                    <li>zvládání stresu a zátěže při studiu</li>
                    <li>tipech pro zlepšení soustředění při studiu</li>
                    <li>time-managementu a organizaci studia</li>
                    <li>studijní motivaci</li>
                    <li>prevenci syndromu vyhoření při studiu</li>
                </ul>
            </template>

            </div>


        <div class="col-12 mt-5">

            <h2>Kontaktujte nás</h2>
            <ContactForm :diagnosed="diagnosed"/>

            <p>
            <a href="https://shs.vse.cz/kontakty/" target="_blank">Další kontakty</a> <br>
            <a href="https://shs.vse.cz/sluzby-strediska/" target="_blank">Služby střediska</a>
            </p>

            <b-button class="btn btn-secondary" @click="$router.push({ name: 'index' })">Zpět na dotazník</b-button>
        </div>





    </div>
</template>

<script>

    import ContactForm from "../components/ContactForm";
    import ResultMoreInfo from "../components/ResultMoreInfo";
    export default {
        name: "ResultPage",
        components: {
            ResultMoreInfo,
            ContactForm
        },
        data() {
            return {
                email: null,
                text: null,
                diagnosed: false,
            };
        },
        created() {
            this.diagnosed = this.$route.params.diagnosed === "true";
        },
        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>

<style scoped>

</style>
