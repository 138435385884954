import Vue from 'vue';
import VueRouter from 'vue-router';

import IndexPage from "./pages/IndexPage";
import ResultPage from "./pages/ResultPage";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: IndexPage,
        },
        {
            path: '/result/:score/:percentile/:diagnosed',
            name: 'result',
            component: ResultPage,
        },

    ],
});

export default router;
