<template>
    <div class="col-12 col-lg-7">
        <div class="row">
            <div class="col-1 id">
                {{ Number.isInteger(id) ? id+1 : id }}.
            </div>

            <div class="col-11 text">
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Question",
    props: ['id', 'text']
}
</script>

<style scoped>

</style>
