import { render, staticRenderFns } from "./ResultPage.vue?vue&type=template&id=751f130d&scoped=true&"
import script from "./ResultPage.vue?vue&type=script&lang=js&"
export * from "./ResultPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "751f130d",
  null
  
)

export default component.exports