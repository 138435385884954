<template>
    <div class="mb-5">
         <div class="alert alert-success" v-if="showSuccess">
            Zpráva byla odeslána.
         </div>
        <div class="alert alert-error" v-if="showError">
            Během odesílání zprávy došlo k chybě. Kontaktujte nás prosím přímo na emailu kristina.brezinova@vse.cz.
        </div>
        <label>Váš email:</label>
        <b-input type="email" class="form-control" v-model="email" required></b-input>
        <label>Text zprávy:</label>
        <b-textarea v-model="text" class="form-control"></b-textarea>
        <button id="send" class="btn btn-primary form-control mt-2" @click="submit" :disabled="submitDisabled">Odeslat email</button>
    </div>
</template>

<script>
export default {
    name: "ContactForm",
    props: {
        'diagnosed': Boolean
    },
    data() {
      return {
          email: '',
          textDiagnosed: 'Dobrý den,\r\rv minulosti mi byla diagnostikovaná specifická porucha učení (např. dyslexie, dysgrafie, dyskalkulie apod.) v pedagogicko-psychologické poradně.\r\rProsím o navržení času schůzky pro úvodní konzultaci k případné diagnostice a případné další podpoře.\r\rS pozdravem,\r',
          text: 'Dobrý den,\r\rprosím o navržení času schůzky pro úvodní konzultaci k případné diagnostice a případné další podpoře.\r\rS pozdravem,\r',
          showSuccess: false,
          showError: false,
      }
    },
    computed: {
        submitDisabled() {
            if(this.email === '' || this.email === undefined || this.text === '' || this.text === undefined) {
                return true;
            }

            if(!this.isEmail(this.email)) {
                return true;
            }

            return false;
        }
    },
    created() {
        if(this.diagnosed) {
            this.text = this.textDiagnosed;
        }
    },
    methods: {
        submit() {
            axios.post('/api/email', {email: this.email, text: this.text})
                .then(response => {
                    if(response.data.status === 'ok') {
                        this.showSuccess = true;
                        this.showError = false;
                    } else {
                        this.showSuccess = false;
                        this.showError = true;
                    }
                });
        },
        isEmail(mail) {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
            {
                return true
            }

            return false
        }
    },

}
</script>

<style scoped>

</style>
