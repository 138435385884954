<template>
    <div class="col-12 col-lg-5 answer" :data-order="(Number.isInteger(id) ? id+1 : id)">
        <b-form-group>
            <b-form-radio-group
                v-model="localValue"
                :options="givenOptions"
                name="radios-btn-default"
                class="answers-group"
                @change="updateValue"
                buttons
            ></b-form-radio-group>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "Answer",
    props: ['value', 'options', 'id'],
    data() {
        return {
            localValue: null,
            optionsOfFour: [
                { text: 'Nikdy', value: 0 },
                { text: 'Občas', value: 1 },
                { text: 'Často', value: 2 },
                { text: 'Téměř vždy', value: 3 }
            ],
            optionsOfThree: [
                { text: 'Ne nebo nevím', value: 0 },
                { text: 'Ne, ale byl/a jsem na vyšetření z důvodu podezření na některou z těchto poruch', value: 1 },
                { text: 'Ano', value: 2 },
            ],
            optionsYesNo: [
                { text: 'Ne', value: 0 },
                { text: 'Ano', value: 1 },
            ]
        };
    },
    computed: {
        givenOptions () {
            if(this.options === 'yesNo') {
                return this.optionsYesNo;
            } else if(this.options === 'ofFour') {
                return this.optionsOfFour;
            } else if(this.options === 'ofThree') {
                return this.optionsOfThree;
            }
        }
    },
    methods: {
        updateValue() {
            this.$emit('input', this.localValue);
        }
    }
}
</script>

<style scoped>

</style>
