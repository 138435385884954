<template>
        <div class="row">
            <div class="col-12 infotext">

                <p>Dobrý den,</p>

                <p>rádi bychom Vás požádali o vyplnění anonymního screeningu zjišťujícího specifické poruchy učení a
                    pozornosti u studentů VŠE.</p>

                <p>Vyberte vždy nejvýstižnější odpověď z možností uvedených za každou otázkou.</p>

                <p>Vyplnění dotazníku netrvá déle než 10 minut.</p>

                <p>Jedná se o orientační metodu, která pomůže zjistit míru vašich potíží při studiu, jako jsou:
                    organizace studijních povinností, osvojování si cizích jazyků, prezentace před publikem,
                    schopnost soustředit se, psaní písemných prací a poznámek z přednášek, práce pod časovým
                    tlakem. Metoda Vám na závěr nabídne doporučení pro další kroky.</p>

                <p>Zkuste se otestovat! Dotazník je anonymní.</p>

                <p>Děkujeme Vám za spolupráci!</p>

                <p>Kristina Březinová<br>
                    <a href="mailto:">kristina.brezinova@vse.cz</a> <br>
                    Akademická psychologická poradna a Centrum pro studenty se specifickými potřebami</p>

                <button @click="testfill" v-if="showTestfill">
                    testfill
                </button>

            </div>
            <div class="col-12 mt-4 questions">
                <div class="row">
                    <div class="col-12 question">
                        <div class="row inner">
                            <Question id="A"
                                      text="V minulosti jsem prošel/la diagnostikou/poradenstvím v Centru pro studenty se specifickými potřebami coby student/ka na Vysoké škole ekonomické."></Question>
                            <Answer id="A" v-model="answers[0][0]" options="yesNo"></Answer>
                        </div>
                    </div>
                    <div class="col-12 question">
                        <div class="row inner">
                            <template v-if="answers[0][0] !== undefined && answers[0][0] === 1">
                                <Question id="B"
                                          text="Byla mi diagnostikována/anebo znovu potvrzena specifická porucha učení (např. dyslexie, dysgrafie, dyskalkulie apod.) anebo porucha pozornosti."></Question>
                                <Answer id="B" v-model="answers[0][1]" options="yesNo"></Answer>
                            </template>
                        </div>
                    </div>
                    <div class="col-12 question" v-for="question in questions">
                        <div class="row inner">

                            <Question :id="question.id" :text="question.text"></Question>

                            <Answer :id="question.id" v-model="answers[1][question.id]" :options="question.id <= 1 ? 'ofThree' : 'ofFour'"></Answer>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 submit-buttom">
                <button
                    id="submit"
                    class="btn btn-primary form-control mt-4"
                    :disabled="submitDisabled()"
                    @click="submit"
                >
                    Vyhodnotit dotazník
                </button>
            </div>
        </div>
</template>

<script>

    import Question from "../components/Question";
    import Answer from "../components/Answer";
    import Vue from 'vue';

    export default {
        name: "IndexPage",
        components: {
            Question, Answer
        },
        data() {
            return {
                questions: null,
                answers: {
                    0: [],
                    1: [],
                },
            };
        },
        computed: {
            showTestfill() {
                return window.laravel.env === 'local';
            }
        },
        methods: {
            testfill() {
                for(let i = 0; i < this.questions.length; i++) {
                    Vue.set(this.answers[1], i, 2);
                }
            },
            submitDisabled() {
                if(this.answers[0][0] === undefined) {
                    console.log('A undefined');
                    return true;
                }

                if(this.answers[0][0] === 1 && this.answers[0][1] === undefined) {
                    console.log('A=1, B=undefined');
                    return true;
                }

                for(let i = 0; i < this.questions.length; i++) {
                    console.log('question #'+(i+1)+' (technical #'+i+') is undefined');
                    if(this.answers[1][i] === undefined) {
                        return true;
                    }
                }

                return false;
            },
            submit() {
                if(this.answers[0][0] === 0) {
                    this.answers[0][1] = 0;
                }

                axios.post('/api/answer', {data: this.answers})
                    .then(response => {
                        this.$router.push({ name: 'result', params: {
                            score: response.data.score,
                            percentile: response.data.percentile,
                            diagnosed: response.data.diagnosed,
                        } })
                    });
            }
        },
        created() {
            axios.get('/api/questions')
                .then(response => {
                    this.questions = response.data.data;
                });
        }
    }
</script>

<style scoped>

</style>
