<template>
    <div>
        <p>Doporučujeme vám objednat se v <a href="https://csp.vse.cz/kontakty/">Centru pro studenty se specifickými potřebami VŠE</a>,
            kde psycholog zajistí diagnostiku vašich obtíží a také může navrhnout úpravu studijních podmínek</p>

        <p class="mb-5">Kontakt: <a href="mailto:kristina.brezinova@vse.cz">kristina.brezinova@vse.cz</a></p>

        <p>Při potvrzení některé formy poruchy je možné požádat o úpravu podmínek v celém průběhu studia
            (nejčastěji se jedná o prodloužení času na zpracování testů).</p>

        <p>Na požádání budete podpořeni v jednání s jednotlivými vyučujícími v souvislosti s vašimi specifickými potřebami.</p>

        <p>Budou s Vámi probrány vhodné strategie učení.</p>
    </div>
</template>

<script>
export default {
    name: "ResultMoreInfo"
}
</script>

<style scoped>

</style>
